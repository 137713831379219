<template>
  <app-header :routes="routes" />
  <Main>
    <a-row :gutter="15">
      <a-col :xs="24">
        <sdCards headless>
          <wizard-wrapper class="shipment-form-steps">
            <steps isSwitch
              :current="current"
              :status="status"
              :steps="steps"
              @onChangeCurrent="changeCurrent"
            >
              <template
                v-for="step in steps"
                v-slot:[step.content]
                :key="step.content"
              >
                <a-row>
                  <a-col :xs="24">
                    <sdCards headless>
                      <single-shipment-form
                        v-if="current > 0"
                        :key="current"
                        :edit="steps[current].status"
                        :clientProductItems="productItems"
                        :currentDestination="$_.find(shipmentRequest.validDestinations, (dest) => {
                          return dest.value === steps[current].content;
                        })"
                        :shipmentRequestId="shipmentRequest._jv.id"
                        :shipment="steps[current].id ? shipment : undefined"
                        @shipments:submitted="nextShipment"
                      />
                    </sdCards>
                  </a-col>
                </a-row>
              </template>
            </steps>
          </wizard-wrapper>
        </sdCards>
      </a-col>
    </a-row>
  </Main>
</template>

<script>
import {
  computed, defineComponent, ref, watch
} from 'vue';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import _ from 'lodash';
import {Main} from '@/components/shared/styledComponents/Main';
import {WizardWrapper} from '@/components/shared/styledComponents/Wizard';
import Steps from '@/components/steps/steps';
import SingleShipmentForm from '@/components/shipments/Form';
import {stepsStatuses} from '@/constants';
import {useI18n} from 'vue-i18n';

export default defineComponent({
  name: 'ShipmentsSteps',
  components: {
    Main, WizardWrapper, Steps, SingleShipmentForm
  },
  setup() {
    const router = useRouter();
    const shipmentRequestId = computed(() => router.currentRoute.value.params.shipmentRequestId);

    const {getters, dispatch} = useStore();
    const shipmentRequest = computed(() => getters.shipmentRequest);
    const shipments = computed(() => getters.shipments);
    const shipment = computed(() => getters.shipment);
    const productItems = computed(() => getters.productItems);

    dispatch('toggleLoading', true);
    Promise.all([
      dispatch('getShipmentRequest', shipmentRequestId.value),
      dispatch('getProductItems')
    ]).then(async () => {
      await dispatch('getShipments', {
        shipmentRequestId: shipmentRequestId.value,
        params: {fields: ['destinationCountry', 'status'], [_.snakeCase('perPage')]: 100}
      });
      dispatch('toggleLoading', false);
      initSteps();
    });

    const edit = ref(false);
    const current = ref(1);
    const status = ref('process');
    const isFinished = ref(false);

    const {t} = useI18n();

    const steps = ref([
      {
        title: t('models.shipmentRequest.entity', 1), content: 'request', status: stepsStatuses.FINISH, id: shipmentRequestId.value
      }
    ]);

    watch(shipmentRequest, () => {
      steps.value = _.concat(steps.value, _.map(shipmentRequest.value.validDestinations, (dest) => {
        return {
          title: dest.label, content: dest.value, status: stepsStatuses.WAIT, id: undefined
        };
      }));
    });

    const initSteps = () => {
      if (!_.isEmpty(shipments.value) && !_.isEmpty(steps.value)) {
        shipments.value.data = _.filter(shipments.value.data, (shipment) => shipment.status !== 'cancelled');
        _.forEach(shipments.value.data, (shipment) => {
          const index = _.findIndex(steps.value, (step) => step.content === shipment.destinationCountry);
          if (index >= 0) {
            steps.value[index].id = shipment._jv.id;
            steps.value[index].status = stepsStatuses.FINISH;
          }
        });
        steps.value = _.sortBy(steps.value, (step) => step.status !== 'finish');
        changeCurrent(shipments.value.data.length + 1);
      } else {
        changeCurrent(1);
      }
    };

    const nextShipment = () => {
      steps.value[current.value].status = stepsStatuses.FINISH;
      steps.value[current.value].id = getters.shipment._jv.id;
      if (current.value + 1 >= steps.value.length) {
        router.push({
          name: 'ClientShipmentRequestDetails',
          params: {shipmentRequestId: shipmentRequestId.value, showInstantQuotes: true}
        });
      } else {
        changeCurrent(current.value + 1);
      }
    };

    const changeCurrent = async (newCurrent) => {
      if (newCurrent >= steps.value.length) {
        newCurrent = 1;
      }
      if (steps.value[current.value]?.status === stepsStatuses.PROCESS) {
        steps.value[current.value].status = steps.value[current.value].id ? stepsStatuses.FINISH : stepsStatuses.WAIT;
      }

      if (newCurrent === 0) {
        router.push({name: 'ClientEditShipmentRequest', params: {shipmentRequestId: shipmentRequestId.value}});
      } else if (steps.value[newCurrent].id) {
        dispatch('toggleLoading', true);
        dispatch('getShipment', {shipmentRequestId: shipmentRequestId.value, shipmentId: steps.value[newCurrent].id}).then(() => {
          dispatch('toggleLoading', false);
        });
      }

      current.value = newCurrent;
      steps.value[newCurrent].status = stepsStatuses.PROCESS;
    };

    watch(current, (value) => {
      if (document.querySelector('.ant-steps').children[value]) {
        document.querySelector('.ant-steps').children[value].scrollIntoView();
      }
    });

    const routes = computed(() => [
      {
        name: 'ClientShipmentRequests',
        breadcrumbName: 'layout.shipmentRequest.index'
      },
      {
        name: 'ClientShipmentRequestDetails',
        params: {shipmentRequestId: shipmentRequestId.value},
        breadcrumbName: 'views.shipmentRequest.details'
      },
      {
        name: 'ClientShipmentsForm',
        params: {shipmentRequestId: shipmentRequestId.value},
        breadcrumbName: 'views.shipmentRequest.shipments'
      }
    ]);

    return {
      shipmentRequest,
      shipment,
      productItems,
      edit,
      steps,
      status,
      isFinished,
      current,
      nextShipment,
      routes,
      changeCurrent
    };
  }
});
</script>

<style lang="scss">
.shipment-form-steps .ant-card,
.shipment-form-steps .ant-row {
  width: 100%
}

.shipment-form-steps .steps-content {
  margin-top: 0px !important;
}

.shipment-form-steps .editable-cell {
  position: relative;
}

.shipment-form-steps .editable-cell .sDash_upload-basic .ant-upload.ant-upload-select {
  border: 0px;
}

.editable-cell .editable-cell-input-wrapper,
.editable-cell .editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell .editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell .editable-cell-icon,
.editable-cell .editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell .editable-cell-icon,
.editable-cell .editable-cell-icon-check {
  color: grey;
}

.editable-cell .editable-add-btn {
  margin-bottom: 8px;
}

span.anticon.anticon-check.editable-cell-icon-check svg {
  margin-top: 20px !important;
}
</style>
